import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import itemsStoreModule from '@/views/items/items/itemsStoreModule'
import categoriesStoreModule from '@/views/items/categories/categoriesStoreModule'
import typesStoreModule from '@/views/items/filters/typesStoreModule'
import statisticsStoreModule from '@/views/dashboard/statisticsStoreModule'
import roleStoreModule from '@/views/staff/role/roleStoreModule'
import rentStoreModule from '@/views/rents/rentStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    items: itemsStoreModule,
    categories: categoriesStoreModule,
    types: typesStoreModule,
    statistics: statisticsStoreModule,
    roles: roleStoreModule,
    rents: rentStoreModule,
  },
  strict: process.env.DEV,
})
