export default [
  {
    path: '/items/:filter?',
    name: 'items.list',
    component: () => import('@/views/items/items/Items.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Product',
      action: 'index',
    },
  },
  {
    path: '/items/store',
    name: 'items.store',
    component: () => import('@/views/items/items/items-store/ItemStore.vue'),
    meta: {
      resource: 'Product',
      action: 'store',
    },
  },
  {
    path: '/items/edit/:id',
    name: 'items.edit',
    component: () => import('@/views/items/items/items-edit/ItemEdit.vue'),
    meta: {
      resource: 'Product',
      action: 'update',
    },
  },
  {
    path: '/items/gallery/:id',
    name: 'items.gallery',
    component: () => import('@/views/items/items/items-edit/ItemEdit.vue'),
    meta: {
      resource: 'Gallery',
      action: 'store',
    },
  },
  {
    path: '/categories',
    name: 'categories.list',
    component: () => import('@/views/items/categories/categories-list/Categories.vue'),
    meta: {
      resource: 'Category',
      action: 'index',
    },
  },
  {
    path: '/filters',
    name: 'filters.list',
    component: () => import('@/views/items/filters/filters-list/Filters.vue'),
    meta: {
      resource: 'Type',
      action: 'index',
    },
  },
]
