import axios from '@axios'

export default {
  namespaced: true,
  state: {
    categories: [],
    categories_by_id: [],
  },
  getters: {
    getCurrentCategories: state => state.categories,
    getCurrentCategoriesById: state => state.categories_by_id,
  },
  mutations: {
    setListCategories: (state, categories) => {
      state.categories = categories
    },
    setListCategoriesById: (state, categories) => {
      state.categories_by_id = categories
    },
  },
  actions: {
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product-sheet/categories', { params: queryParams })
          .then(response => {
            ctx.commit('setListCategories', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCategoriesById(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product-sheet/categories', { params: queryParams })
          .then(response => {
            ctx.commit('setListCategoriesById', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addCategory(ctx, categoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product-sheet/categories', categoryData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateCategory(ctx, categoryData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/product-sheet/categories/${categoryData.id}`, categoryData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addSubCategory(ctx, subCategoryData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product-sheet/categories', subCategoryData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteCategory(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product-sheet/categories/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
