import axios from '@axios'

export default {
  namespaced: true,
  state: {
    rents: [],
    currentRent: [],
  },
  getters: {
    getRents: state => state.rents,
    getCurrentRent: state => state.currentRent,
  },
  mutations: {
    setRents: (state, rents) => {
      state.rents = rents
    },
    setCurrentRent: (state, currentRent) => {
      state.currentRent = currentRent
    },
  },
  actions: {
    fetchRents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/ecommerce/rent-request', { params: queryParams })
          .then(response => {
            ctx.commit('setRents', response.data.data.rents)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchRent(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/ecommerce/rent-request/${id}`)
          .then(response => {
            ctx.commit('setCurrentRent', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateRent(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/ecommerce/rent-request/${itemData.id}`, itemData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
