import axios from '@axios'

export default {
  namespaced: true,
  state: {
    types: [],
  },
  getters: {
    getCurrentTypes: state => state.types,
  },
  mutations: {
    setListTypes: (state, types) => {
      state.types = types
    },
  },
  actions: {
    fetchTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product-sheet/types', { params: queryParams })
          .then(response => {
            ctx.commit('setListTypes', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addType(ctx, typeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product-sheet/types', typeData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateType(ctx, typeData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/product-sheet/types/${typeData.id}`, typeData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteType(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product-sheet/types/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addFilter(ctx, filterData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product-sheet/filters', filterData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateFilter(ctx, filterData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/product-sheet/filters/${filterData.id}`, filterData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteFilter(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product-sheet/filters/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
