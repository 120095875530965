export default [
  {
    path: '/staff',
    name: 'staff-list',
    meta: {
      resource: 'Staff',
      action: 'index',
    },
    component: () => import('@/views/staff/staff/staff-list/Staff.vue'),
  },
  {
    path: '/staff/view/:id',
    name: 'staff-view',
    component: () => import('@/views/staff/staff/staff-view/StaffView.vue'),
  },
  {
    path: '/roles',
    name: 'roles-list',
    meta: {
      resource: 'Role',
      action: 'index',
    },
    component: () => import('@/views/staff/role/role-list/Role.vue'),
  },
]
