import Vue from 'vue'

// axios
import axios from 'axios'
import { initialAbility } from '@/libs/acl/config'
import ability from '@/libs/acl/ability'
import router from '@/router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${process.env.VUE_APP_APIURL}staff`,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(null, error => {
  if (error.response.status === 403) {
    // Remove userData from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('accessToken')

    // Reset ability
    ability.update(initialAbility)

    // Redirect to login page
    router.push({ name: 'auth-login' })
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
