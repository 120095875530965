import axios from '@axios'

export default {
  namespaced: true,
  state: {
    roles: [],
    permissions: [],
  },
  getters: {
    GetCurrentRoles: state => state.roles,
    GetCurrentPermissions: state => state.permissions,
  },
  mutations: {
    SetRoles: (state, roles) => {
      state.roles = roles
    },
    SetPermissions: (state, permissions) => {
      state.permissions = permissions
    },
  },
  actions: {
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/accounts/roles', { params: queryParams })
          .then(response => {
            ctx.commit('SetRoles', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/accounts/roles/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/accounts/roles', userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateRole(ctx, roleData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/accounts/roles/${roleData.id}`, roleData.roleData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteRole(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accounts/roles/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteRole(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/accounts/roles/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/accounts/roles', { params: queryParams })
          .then(response => {
            ctx.commit('SetPermissions', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
