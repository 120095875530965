export default [
  {
    path: '/rents',
    name: 'rents',
    meta: {
      resource: 'Rent',
      action: 'index',
    },
    component: () => import('@/views/rents/Rent.vue'),
  },
  {
    path: '/rent/:id',
    name: 'rent.edit',
    component: () => import('@/views/rents/rents-edit/RentEdit.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      resource: 'Rent',
      action: 'show',
    },
  },
]
