import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
    draftItems: [],
    currentItem: [],
    currentPagination: [],
  },
  getters: {
    getListItems: state => state.items,
    getDraftItem: state => state.draftItems,
    getCurrentItem: state => state.currentItem,
    getCurrentPagination: state => {
      if (state.currentPagination && state.currentPagination.currentPage) {
        return state.currentPagination
      }
      return { currentPage: 1 }
    },
  },
  mutations: {
    setListItems: (state, items) => {
      state.items = items
    },
    addDraftItem: (state, item) => {
      state.draftItems = item
    },
    setCurrentItem: (state, item) => {
      state.currentItem = item
    },
    setCurrentPagination: (state, paginationData) => {
      state.currentPagination = paginationData
    },
  },
  actions: {
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/product-sheet/products', { params: queryParams })
          .then(response => {
            ctx.commit('setListItems', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/product-sheet/products/${id}`)
          .then(response => {
            ctx.commit('setCurrentItem', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/product-sheet/products', itemData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/product-sheet/products/${itemData.id}`, itemData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteItem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product-sheet/products/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteItem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product-sheet/products/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /// ///////////////////
    /// ///// IMAGES //////
    /// ///////////////////
    updateImage(ctx, imageData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/product-sheet/galleries/${imageData.id}`, imageData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    forceDeleteImage(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/product-sheet/galleries/${id}`, { params: { definitive_delete: true } })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    /// ///////////////////
    /// ///// QR //////
    /// ///////////////////
    downloadQr(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/product-sheet/qr/${itemData.id}`, {
            headers: { 'Content-Type': 'application/pdf' },
            responseType: 'blob',
          })
          .then(response => {
            const blob = new Blob([response.data], {
              type: 'application/pdf',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${itemData.code}.pdf`
            link.click()
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
